/*!
 * froala_editor v2.9.3 (https://www.froala.com/wysiwyg-editor)
 * License https://froala.com/wysiwyg-editor/terms/
 * Copyright 2014-2019 Froala Labs
 */

.clearfix::after {
  clear: both;
  display: block;
  content: "";
  height: 0;
}
.hide-by-clipping {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
}
.fr-element,
.fr-element:focus {
  outline: 0px solid transparent;
}
.fr-box.fr-basic .fr-element {
  color: #000000;
  padding: 16px;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  overflow-x: auto;
  min-height: 52px;
}
.fr-box.fr-basic.fr-rtl .fr-element {
  text-align: right;
}
.fr-element {
  background: transparent;
  position: relative;
  z-index: 2;
  -webkit-user-select: auto;
}
.fr-element a {
  user-select: auto;
  -o-user-select: auto;
  -moz-user-select: auto;
  -khtml-user-select: auto;
  -webkit-user-select: auto;
  -ms-user-select: auto;
}
.fr-element.fr-disabled {
  user-select: none;
  -o-user-select: none;
  -moz-user-select: none;
  -khtml-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
}
.fr-element [contenteditable="true"] {
  outline: 0px solid transparent;
}
.fr-box a.fr-floating-btn {
  -webkit-box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 1px 1px rgba(0, 0, 0, 0.16);
  -moz-box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 1px 1px rgba(0, 0, 0, 0.16);
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 1px 1px rgba(0, 0, 0, 0.16);
  border-radius: 100%;
  -moz-border-radius: 100%;
  -webkit-border-radius: 100%;
  -moz-background-clip: padding;
  -webkit-background-clip: padding-box;
  background-clip: padding-box;
  height: 32px;
  width: 32px;
  background: #ffffff;
  color: #1e88e5;
  -webkit-transition: background 0.2s ease 0s, color 0.2s ease 0s, transform 0.2s ease 0s;
  -moz-transition: background 0.2s ease 0s, color 0.2s ease 0s, transform 0.2s ease 0s;
  -ms-transition: background 0.2s ease 0s, color 0.2s ease 0s, transform 0.2s ease 0s;
  -o-transition: background 0.2s ease 0s, color 0.2s ease 0s, transform 0.2s ease 0s;
  outline: none;
  left: 0;
  top: 0;
  line-height: 32px;
  -webkit-transform: scale(0);
  -moz-transform: scale(0);
  -ms-transform: scale(0);
  -o-transform: scale(0);
  text-align: center;
  display: block;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  border: none;
}
.fr-box a.fr-floating-btn svg {
  -webkit-transition: transform 0.2s ease 0s;
  -moz-transition: transform 0.2s ease 0s;
  -ms-transition: transform 0.2s ease 0s;
  -o-transition: transform 0.2s ease 0s;
  fill: #1e88e5;
}
.fr-box a.fr-floating-btn i,
.fr-box a.fr-floating-btn svg {
  font-size: 14px;
  line-height: 32px;
}
.fr-box a.fr-floating-btn.fr-btn + .fr-btn {
  margin-left: 10px;
}
.fr-box a.fr-floating-btn:hover {
  background: #ebebeb;
  cursor: pointer;
}
.fr-box a.fr-floating-btn:hover svg {
  fill: #1e88e5;
}
.fr-box .fr-visible a.fr-floating-btn {
  -webkit-transform: scale(1);
  -moz-transform: scale(1);
  -ms-transform: scale(1);
  -o-transform: scale(1);
}
iframe.fr-iframe {
  width: 100%;
  border: none;
  position: relative;
  display: block;
  z-index: 2;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
.fr-wrapper {
  position: relative;
  z-index: 1;
}
.fr-wrapper::after {
  clear: both;
  display: block;
  content: "";
  height: 0;
}
.fr-wrapper .fr-placeholder {
  position: absolute;
  font-size: 12px;
  color: #aaaaaa;
  z-index: 1;
  display: none;
  top: 0;
  left: 0;
  right: 0;
  overflow: hidden;
}
.fr-wrapper.show-placeholder .fr-placeholder {
  display: block;
}
.fr-wrapper ::-moz-selection {
  background: #b5d6fd;
  color: #000000;
}
.fr-wrapper ::selection {
  background: #b5d6fd;
  color: #000000;
}
.fr-box.fr-basic .fr-wrapper {
  background: #ffffff;
  border: 0px;
  border-top: 0;
  top: 0;
  left: 0;
}
.fr-box.fr-basic.fr-top .fr-wrapper {
  border-top: 0;
  border-radius: 0 0 2px 2px;
  -moz-border-radius: 0 0 2px 2px;
  -webkit-border-radius: 0 0 2px 2px;
  -moz-background-clip: padding;
  -webkit-background-clip: padding-box;
  background-clip: padding-box;
  -webkit-box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 1px 1px rgba(0, 0, 0, 0.16);
  -moz-box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 1px 1px rgba(0, 0, 0, 0.16);
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 1px 1px rgba(0, 0, 0, 0.16);
}
.fr-box.fr-basic.fr-bottom .fr-wrapper {
  border-bottom: 0;
  border-radius: 2px 2px 0 0;
  -moz-border-radius: 2px 2px 0 0;
  -webkit-border-radius: 2px 2px 0 0;
  -moz-background-clip: padding;
  -webkit-background-clip: padding-box;
  background-clip: padding-box;
  -webkit-box-shadow: 0 -1px 3px rgba(0, 0, 0, 0.12), 0 -1px 1px 1px rgba(0, 0, 0, 0.16);
  -moz-box-shadow: 0 -1px 3px rgba(0, 0, 0, 0.12), 0 -1px 1px 1px rgba(0, 0, 0, 0.16);
  box-shadow: 0 -1px 3px rgba(0, 0, 0, 0.12), 0 -1px 1px 1px rgba(0, 0, 0, 0.16);
}
@media (min-width: 992px) {
  .fr-box.fr-document {
    min-width: 21cm;
  }
  .fr-box.fr-document .fr-wrapper {
    text-align: center;
    padding: 30px;
    min-width: 21cm;
    background: #EFEFEF;
  }
  .fr-box.fr-document .fr-wrapper .fr-element {
    text-align: left;
    background: #FFF;
    width: 21cm;
    margin: auto;
    min-height: 26cm !important;
    padding: 1cm 2cm;
    -webkit-box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 1px 1px rgba(0, 0, 0, 0.16);
    -moz-box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 1px 1px rgba(0, 0, 0, 0.16);
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 1px 1px rgba(0, 0, 0, 0.16);
    overflow: visible;
  }
  .fr-box.fr-document .fr-wrapper .fr-element hr {
    margin-left: -2cm;
    margin-right: -2cm;
    background: #EFEFEF;
    height: 1cm;
    outline: none;
    border: none;
  }
}
.fr-tooltip {
  position: absolute;
  top: 0;
  left: 0;
  padding: 0 8px;
  border-radius: 2px;
  -moz-border-radius: 2px;
  -webkit-border-radius: 2px;
  -moz-background-clip: padding;
  -webkit-background-clip: padding-box;
  background-clip: padding-box;
  -webkit-box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 2px 2px 1px rgba(0, 0, 0, 0.14);
  -moz-box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 2px 2px 1px rgba(0, 0, 0, 0.14);
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 2px 2px 1px rgba(0, 0, 0, 0.14);
  background: #222222;
  color: #ffffff;
  font-size: 11px;
  line-height: 22px;
  font-family: Arial, Helvetica, sans-serif;
  -webkit-transition: opacity 0.2s ease 0s;
  -moz-transition: opacity 0.2s ease 0s;
  -ms-transition: opacity 0.2s ease 0s;
  -o-transition: opacity 0.2s ease 0s;
  -webkit-opacity: 0;
  -moz-opacity: 0;
  opacity: 0;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
  left: -3000px;
  user-select: none;
  -o-user-select: none;
  -moz-user-select: none;
  -khtml-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  z-index: 2147483647;
  text-rendering: optimizelegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.fr-tooltip.fr-visible {
  -webkit-opacity: 1;
  -moz-opacity: 1;
  opacity: 1;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
}
.fr-toolbar .fr-btn-wrap,
.fr-popup .fr-btn-wrap {
  float: left;
  white-space: nowrap;
  position: relative;
}
.fr-toolbar .fr-btn-wrap.fr-hidden,
.fr-popup .fr-btn-wrap.fr-hidden {
  display: none;
}
.fr-toolbar .fr-command.fr-btn,
.fr-popup .fr-command.fr-btn {
  background: transparent;
  color: #222222;
  -moz-outline: 0;
  outline: 0;
  border: 0;
  line-height: 1;
  cursor: pointer;
  text-align: left;
  margin: 0px 2px;
  -webkit-transition: background 0.2s ease 0s;
  -moz-transition: background 0.2s ease 0s;
  -ms-transition: background 0.2s ease 0s;
  -o-transition: background 0.2s ease 0s;
  border-radius: 0;
  -moz-border-radius: 0;
  -webkit-border-radius: 0;
  -moz-background-clip: padding;
  -webkit-background-clip: padding-box;
  background-clip: padding-box;
  z-index: 2;
  position: relative;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  text-decoration: none;
  user-select: none;
  -o-user-select: none;
  -moz-user-select: none;
  -khtml-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  float: left;
  padding: 0;
  width: 38px;
  height: 38px;
}
.fr-toolbar .fr-command.fr-btn::-moz-focus-inner,
.fr-popup .fr-command.fr-btn::-moz-focus-inner {
  border: 0;
  padding: 0;
}
.fr-toolbar .fr-command.fr-btn.fr-btn-text,
.fr-popup .fr-command.fr-btn.fr-btn-text {
  width: auto;
}
.fr-toolbar .fr-command.fr-btn i,
.fr-popup .fr-command.fr-btn i,
.fr-toolbar .fr-command.fr-btn svg,
.fr-popup .fr-command.fr-btn svg {
  display: block;
  font-size: 14px;
  width: 14px;
  margin: 12px 12px;
  text-align: center;
  float: none;
}
.fr-toolbar .fr-command.fr-btn span.fr-sr-only,
.fr-popup .fr-command.fr-btn span.fr-sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
}
.fr-toolbar .fr-command.fr-btn span,
.fr-popup .fr-command.fr-btn span {
  font-size: 14px;
  display: block;
  line-height: 17px;
  min-width: 34px;
  float: left;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  height: 17px;
  font-weight: bold;
  padding: 0 2px;
}
.fr-toolbar .fr-command.fr-btn img,
.fr-popup .fr-command.fr-btn img {
  margin: 12px 12px;
  width: 14px;
}
.fr-toolbar .fr-command.fr-btn.fr-active,
.fr-popup .fr-command.fr-btn.fr-active {
  color: #1e88e5;
  background: transparent;
}
.fr-toolbar .fr-command.fr-btn.fr-dropdown.fr-selection,
.fr-popup .fr-command.fr-btn.fr-dropdown.fr-selection {
  width: auto;
}
.fr-toolbar .fr-command.fr-btn.fr-dropdown.fr-selection span,
.fr-popup .fr-command.fr-btn.fr-dropdown.fr-selection span {
  font-weight: normal;
}
.fr-toolbar .fr-command.fr-btn.fr-dropdown i,
.fr-popup .fr-command.fr-btn.fr-dropdown i,
.fr-toolbar .fr-command.fr-btn.fr-dropdown span,
.fr-popup .fr-command.fr-btn.fr-dropdown span,
.fr-toolbar .fr-command.fr-btn.fr-dropdown img,
.fr-popup .fr-command.fr-btn.fr-dropdown img,
.fr-toolbar .fr-command.fr-btn.fr-dropdown svg,
.fr-popup .fr-command.fr-btn.fr-dropdown svg {
  margin-left: 8px;
  margin-right: 16px;
}
.fr-toolbar .fr-command.fr-btn.fr-dropdown.fr-active,
.fr-popup .fr-command.fr-btn.fr-dropdown.fr-active {
  color: #222222;
  background: #d6d6d6;
}
.fr-toolbar .fr-command.fr-btn.fr-dropdown.fr-active:hover,
.fr-popup .fr-command.fr-btn.fr-dropdown.fr-active:hover,
.fr-toolbar .fr-command.fr-btn.fr-dropdown.fr-active:focus,
.fr-popup .fr-command.fr-btn.fr-dropdown.fr-active:focus {
  background: #d6d6d6 !important;
  color: #222222 !important;
}
.fr-toolbar .fr-command.fr-btn.fr-dropdown.fr-active:hover::after,
.fr-popup .fr-command.fr-btn.fr-dropdown.fr-active:hover::after,
.fr-toolbar .fr-command.fr-btn.fr-dropdown.fr-active:focus::after,
.fr-popup .fr-command.fr-btn.fr-dropdown.fr-active:focus::after {
  border-top-color: #222222 !important;
}
.fr-toolbar .fr-command.fr-btn.fr-dropdown::after,
.fr-popup .fr-command.fr-btn.fr-dropdown::after {
  position: absolute;
  width: 0;
  height: 0;
  border-left: 4px solid transparent;
  border-right: 4px solid transparent;
  border-top: 4px solid #222222;
  right: 4px;
  top: 17px;
  content: "";
}
.fr-toolbar .fr-command.fr-btn.fr-disabled,
.fr-popup .fr-command.fr-btn.fr-disabled {
  color: #bdbdbd;
  cursor: default;
}
.fr-toolbar .fr-command.fr-btn.fr-disabled::after,
.fr-popup .fr-command.fr-btn.fr-disabled::after {
  border-top-color: #bdbdbd !important;
}
.fr-toolbar .fr-command.fr-btn.fr-hidden,
.fr-popup .fr-command.fr-btn.fr-hidden {
  display: none;
}
.fr-toolbar.fr-disabled .fr-btn,
.fr-popup.fr-disabled .fr-btn,
.fr-toolbar.fr-disabled .fr-btn.fr-active,
.fr-popup.fr-disabled .fr-btn.fr-active {
  color: #bdbdbd;
}
.fr-toolbar.fr-disabled .fr-btn.fr-dropdown::after,
.fr-popup.fr-disabled .fr-btn.fr-dropdown::after,
.fr-toolbar.fr-disabled .fr-btn.fr-active.fr-dropdown::after,
.fr-popup.fr-disabled .fr-btn.fr-active.fr-dropdown::after {
  border-top-color: #bdbdbd;
}
.fr-toolbar.fr-rtl .fr-command.fr-btn,
.fr-popup.fr-rtl .fr-command.fr-btn,
.fr-toolbar.fr-rtl .fr-btn-wrap,
.fr-popup.fr-rtl .fr-btn-wrap {
  float: right;
}
.fr-toolbar.fr-inline > .fr-command.fr-btn:not(.fr-hidden),
.fr-toolbar.fr-inline > .fr-btn-wrap:not(.fr-hidden) {
  display: -webkit-inline-flex;
  display: -ms-inline-flexbox;
  display: inline-flex;
  float: none;
}
.fr-desktop .fr-command:hover,
.fr-desktop .fr-command:focus,
.fr-desktop .fr-command.fr-btn-hover,
.fr-desktop .fr-command.fr-expanded {
  outline: 0;
  color: #222222;
  background: #ebebeb;
}
.fr-desktop .fr-command:hover::after,
.fr-desktop .fr-command:focus::after,
.fr-desktop .fr-command.fr-btn-hover::after,
.fr-desktop .fr-command.fr-expanded::after {
  border-top-color: #222222 !important;
}
.fr-desktop .fr-command.fr-selected {
  color: #222222;
  background: #d6d6d6;
}
.fr-desktop .fr-command.fr-active:hover,
.fr-desktop .fr-command.fr-active:focus,
.fr-desktop .fr-command.fr-active.fr-btn-hover,
.fr-desktop .fr-command.fr-active.fr-expanded {
  color: #1e88e5;
  background: #ebebeb;
}
.fr-desktop .fr-command.fr-active.fr-selected {
  color: #1e88e5;
  background: #d6d6d6;
}
.fr-desktop .fr-command.fr-disabled:hover,
.fr-desktop .fr-command.fr-disabled:focus,
.fr-desktop .fr-command.fr-disabled.fr-selected {
  background: transparent;
}
.fr-desktop.fr-disabled .fr-command:hover,
.fr-desktop.fr-disabled .fr-command:focus,
.fr-desktop.fr-disabled .fr-command.fr-selected {
  background: transparent;
}
.fr-toolbar.fr-mobile .fr-command.fr-blink,
.fr-popup.fr-mobile .fr-command.fr-blink {
  background: transparent;
}
.fr-command.fr-btn.fr-options {
  width: 16px;
  margin-left: -5px;
}
.fr-command.fr-btn.fr-options.fr-btn-hover,
.fr-command.fr-btn.fr-options:hover,
.fr-command.fr-btn.fr-options:focus {
  border-left: solid 1px #fafafa;
}
.fr-command.fr-btn + .fr-dropdown-menu {
  display: inline-block;
  position: absolute;
  right: auto;
  bottom: auto;
  height: auto;
  z-index: 4;
  -webkit-overflow-scrolling: touch;
  overflow: hidden;
  zoom: 1;
  border-radius: 0 0 2px 2px;
  -moz-border-radius: 0 0 2px 2px;
  -webkit-border-radius: 0 0 2px 2px;
  -moz-background-clip: padding;
  -webkit-background-clip: padding-box;
  background-clip: padding-box;
}
.fr-command.fr-btn + .fr-dropdown-menu.test-height .fr-dropdown-wrapper {
  -webkit-transition: none;
  -moz-transition: none;
  -ms-transition: none;
  -o-transition: none;
  height: auto;
  max-height: 275px;
}
.fr-command.fr-btn + .fr-dropdown-menu .fr-dropdown-wrapper {
  background: #ffffff;
  padding: 0;
  margin: auto;
  display: inline-block;
  text-align: left;
  position: relative;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-transition: max-height 0.2s ease 0s;
  -moz-transition: max-height 0.2s ease 0s;
  -ms-transition: max-height 0.2s ease 0s;
  -o-transition: max-height 0.2s ease 0s;
  margin-top: 0;
  float: left;
  max-height: 0;
  height: 0;
  margin-top: 0 !important;
}
.fr-command.fr-btn + .fr-dropdown-menu .fr-dropdown-wrapper .fr-dropdown-content {
  overflow: auto;
  position: relative;
  max-height: 275px;
}
.fr-command.fr-btn + .fr-dropdown-menu .fr-dropdown-wrapper .fr-dropdown-content ul.fr-dropdown-list {
  list-style-type: none;
  margin: 0;
  padding: 0;
}
.fr-command.fr-btn + .fr-dropdown-menu .fr-dropdown-wrapper .fr-dropdown-content ul.fr-dropdown-list li {
  padding: 0;
  margin: 0;
  font-size: 15px;
}
.fr-command.fr-btn + .fr-dropdown-menu .fr-dropdown-wrapper .fr-dropdown-content ul.fr-dropdown-list li a {
  padding: 0 24px;
  line-height: 200%;
  display: block;
  cursor: pointer;
  white-space: nowrap;
  color: inherit;
  text-decoration: none;
}
.fr-command.fr-btn + .fr-dropdown-menu .fr-dropdown-wrapper .fr-dropdown-content ul.fr-dropdown-list li a.fr-active {
  background: #d6d6d6;
}
.fr-command.fr-btn + .fr-dropdown-menu .fr-dropdown-wrapper .fr-dropdown-content ul.fr-dropdown-list li a.fr-disabled {
  color: #bdbdbd;
  cursor: default;
}
.fr-command.fr-btn + .fr-dropdown-menu .fr-dropdown-wrapper .fr-dropdown-content ul.fr-dropdown-list li a .fr-shortcut {
  float: right;
  margin-left: 32px;
  font-weight: bold;
  -webkit-opacity: 0.75;
  -moz-opacity: 0.75;
  opacity: 0.75;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
}
.fr-command.fr-btn:not(.fr-active) + .fr-dropdown-menu {
  left: -3000px !important;
}
.fr-command.fr-btn.fr-active + .fr-dropdown-menu {
  display: inline-block;
  -webkit-box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 2px 2px 1px rgba(0, 0, 0, 0.14);
  -moz-box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 2px 2px 1px rgba(0, 0, 0, 0.14);
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 2px 2px 1px rgba(0, 0, 0, 0.14);
}
.fr-command.fr-btn.fr-active + .fr-dropdown-menu .fr-dropdown-wrapper {
  height: auto;
  max-height: 275px;
}
.fr-bottom > .fr-command.fr-btn + .fr-dropdown-menu {
  border-radius: 2px 2px 0 0;
  -moz-border-radius: 2px 2px 0 0;
  -webkit-border-radius: 2px 2px 0 0;
  -moz-background-clip: padding;
  -webkit-background-clip: padding-box;
  background-clip: padding-box;
}
.fr-toolbar.fr-rtl .fr-dropdown-wrapper,
.fr-popup.fr-rtl .fr-dropdown-wrapper {
  text-align: right !important;
}
body.prevent-scroll {
  overflow: hidden;
}
body.prevent-scroll.fr-mobile {
  position: fixed;
  -webkit-overflow-scrolling: touch;
}
.fr-modal {
  color: #222222;
  font-family: Arial, Helvetica, sans-serif;
  position: fixed;
  overflow-x: auto;
  overflow-y: scroll;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  z-index: 2147483640;
  text-rendering: optimizelegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  line-height: 1.2;
}
.fr-modal.fr-middle .fr-modal-wrapper {
  margin-top: 0;
  margin-bottom: 0;
  margin-left: auto;
  margin-right: auto;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  position: absolute;
}
.fr-modal .fr-modal-wrapper {
  border-radius: 2px;
  -moz-border-radius: 2px;
  -webkit-border-radius: 2px;
  -moz-background-clip: padding;
  -webkit-background-clip: padding-box;
  background-clip: padding-box;
  margin: 20px auto;
  display: inline-block;
  background: #ffffff;
  min-width: 300px;
  -webkit-box-shadow: 0 5px 8px rgba(0, 0, 0, 0.19), 0 4px 3px 1px rgba(0, 0, 0, 0.14);
  -moz-box-shadow: 0 5px 8px rgba(0, 0, 0, 0.19), 0 4px 3px 1px rgba(0, 0, 0, 0.14);
  box-shadow: 0 5px 8px rgba(0, 0, 0, 0.19), 0 4px 3px 1px rgba(0, 0, 0, 0.14);
  border: 0px;
  border-top: 5px solid #222222;
  overflow: hidden;
  width: 90%;
  position: relative;
}
@media (min-width: 768px) and (max-width: 991px) {
  .fr-modal .fr-modal-wrapper {
    margin: 30px auto;
    width: 70%;
  }
}
@media (min-width: 992px) {
  .fr-modal .fr-modal-wrapper {
    margin: 50px auto;
    width: 960px;
  }
}
.fr-modal .fr-modal-wrapper .fr-modal-head {
  background: #ffffff;
  -webkit-box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 2px 2px 1px rgba(0, 0, 0, 0.14);
  -moz-box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 2px 2px 1px rgba(0, 0, 0, 0.14);
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 2px 2px 1px rgba(0, 0, 0, 0.14);
  border-bottom: 0px;
  overflow: hidden;
  position: absolute;
  width: 100%;
  min-height: 42px;
  z-index: 3;
  -webkit-transition: height 0.2s ease 0s;
  -moz-transition: height 0.2s ease 0s;
  -ms-transition: height 0.2s ease 0s;
  -o-transition: height 0.2s ease 0s;
}
.fr-modal .fr-modal-wrapper .fr-modal-head .fr-modal-close {
  padding: 12px;
  width: 20px;
  font-size: 30px;
  cursor: pointer;
  line-height: 18px;
  color: #222222;
  -webkit-box-sizing: content-box;
  -moz-box-sizing: content-box;
  box-sizing: content-box;
  position: absolute;
  top: 0;
  right: 0;
  -webkit-transition: color 0.2s ease 0s;
  -moz-transition: color 0.2s ease 0s;
  -ms-transition: color 0.2s ease 0s;
  -o-transition: color 0.2s ease 0s;
}
.fr-modal .fr-modal-wrapper .fr-modal-head h4 {
  font-size: 18px;
  padding: 12px 10px;
  margin: 0;
  font-weight: 400;
  line-height: 18px;
  display: inline-block;
  float: left;
}
.fr-modal .fr-modal-wrapper div.fr-modal-body {
  height: 100%;
  min-height: 150px;
  overflow-y: scroll;
  padding-bottom: 10px;
}
.fr-modal .fr-modal-wrapper div.fr-modal-body:focus {
  outline: 0;
}
.fr-modal .fr-modal-wrapper div.fr-modal-body button.fr-command {
  height: 36px;
  line-height: 1;
  color: #1e88e5;
  padding: 10px;
  cursor: pointer;
  text-decoration: none;
  border: none;
  background: none;
  font-size: 16px;
  outline: none;
  -webkit-transition: background 0.2s ease 0s;
  -moz-transition: background 0.2s ease 0s;
  -ms-transition: background 0.2s ease 0s;
  -o-transition: background 0.2s ease 0s;
  border-radius: 2px;
  -moz-border-radius: 2px;
  -webkit-border-radius: 2px;
  -moz-background-clip: padding;
  -webkit-background-clip: padding-box;
  background-clip: padding-box;
}
.fr-modal .fr-modal-wrapper div.fr-modal-body button.fr-command + button {
  margin-left: 24px;
}
.fr-modal .fr-modal-wrapper div.fr-modal-body button.fr-command:hover,
.fr-modal .fr-modal-wrapper div.fr-modal-body button.fr-command:focus {
  background: #ebebeb;
  color: #1e88e5;
}
.fr-modal .fr-modal-wrapper div.fr-modal-body button.fr-command:active {
  background: #d6d6d6;
  color: #1e88e5;
}
.fr-modal .fr-modal-wrapper div.fr-modal-body button::-moz-focus-inner {
  border: 0;
}
.fr-desktop .fr-modal-wrapper .fr-modal-head i:hover {
  background: #ebebeb;
}
.fr-overlay {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: #000000;
  -webkit-opacity: 0.5;
  -moz-opacity: 0.5;
  opacity: 0.5;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
  z-index: 2147483639;
}
.fr-popup {
  position: absolute;
  display: none;
  color: #222222;
  background: #ffffff;
  -webkit-box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 1px 1px rgba(0, 0, 0, 0.16);
  -moz-box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 1px 1px rgba(0, 0, 0, 0.16);
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 1px 1px rgba(0, 0, 0, 0.16);
  border-radius: 2px;
  -moz-border-radius: 2px;
  -webkit-border-radius: 2px;
  -moz-background-clip: padding;
  -webkit-background-clip: padding-box;
  background-clip: padding-box;
  font-family: Arial, Helvetica, sans-serif;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  user-select: none;
  -o-user-select: none;
  -moz-user-select: none;
  -khtml-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  margin-top: 10px;
  z-index: 2147483635;
  text-align: left;
  border: 0px;
  border-top: 5px solid #222222;
  text-rendering: optimizelegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  line-height: 1.2;
}
.fr-popup .fr-input-focus {
  background: #f5f5f5;
}
.fr-popup.fr-above {
  margin-top: -10px;
  border-top: 0;
  border-bottom: 5px solid #222222;
  -webkit-box-shadow: 0 -1px 3px rgba(0, 0, 0, 0.12), 0 -1px 1px 1px rgba(0, 0, 0, 0.16);
  -moz-box-shadow: 0 -1px 3px rgba(0, 0, 0, 0.12), 0 -1px 1px 1px rgba(0, 0, 0, 0.16);
  box-shadow: 0 -1px 3px rgba(0, 0, 0, 0.12), 0 -1px 1px 1px rgba(0, 0, 0, 0.16);
}
.fr-popup.fr-active {
  display: block;
}
.fr-popup.fr-hidden {
  -webkit-opacity: 0;
  -moz-opacity: 0;
  opacity: 0;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
}
.fr-popup.fr-empty {
  display: none !important;
}
.fr-popup .fr-hs {
  display: block !important;
}
.fr-popup .fr-hs.fr-hidden {
  display: none !important;
}
.fr-popup .fr-input-line {
  position: relative;
  padding: 8px 0;
}
.fr-popup .fr-input-line input[type="text"],
.fr-popup .fr-input-line textarea {
  width: 100%;
  margin: 0px 0 1px 0;
  border: none;
  border-bottom: solid 1px #bdbdbd;
  color: #222222;
  font-size: 14px;
  padding: 6px 0 2px;
  background: rgba(0, 0, 0, 0);
  position: relative;
  z-index: 2;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
.fr-popup .fr-input-line input[type="text"]:focus,
.fr-popup .fr-input-line textarea:focus {
  border-bottom: solid 2px #1e88e5;
  margin-bottom: 0px;
}
.fr-popup .fr-input-line input + label,
.fr-popup .fr-input-line textarea + label {
  position: absolute;
  top: 0;
  left: 0;
  font-size: 12px;
  color: rgba(0, 0, 0, 0);
  -webkit-transition: color 0.2s ease 0s;
  -moz-transition: color 0.2s ease 0s;
  -ms-transition: color 0.2s ease 0s;
  -o-transition: color 0.2s ease 0s;
  z-index: 3;
  width: 100%;
  display: block;
  background: #ffffff;
}
.fr-popup .fr-input-line input.fr-not-empty:focus + label,
.fr-popup .fr-input-line textarea.fr-not-empty:focus + label {
  color: #1e88e5;
}
.fr-popup .fr-input-line input.fr-not-empty + label,
.fr-popup .fr-input-line textarea.fr-not-empty + label {
  color: #808080;
}
.fr-popup input,
.fr-popup textarea {
  user-select: text;
  -o-user-select: text;
  -moz-user-select: text;
  -khtml-user-select: text;
  -webkit-user-select: text;
  -ms-user-select: text;
  border-radius: 0;
  -moz-border-radius: 0;
  -webkit-border-radius: 0;
  -moz-background-clip: padding;
  -webkit-background-clip: padding-box;
  background-clip: padding-box;
  outline: none;
}
.fr-popup textarea {
  resize: none;
}
.fr-popup .fr-buttons {
  -webkit-box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 1px 1px rgba(0, 0, 0, 0.16);
  -moz-box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 1px 1px rgba(0, 0, 0, 0.16);
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 1px 1px rgba(0, 0, 0, 0.16);
  padding: 0 2px;
  white-space: nowrap;
  line-height: 0;
  border-bottom: 0px;
}
.fr-popup .fr-buttons::after {
  clear: both;
  display: block;
  content: "";
  height: 0;
}
.fr-popup .fr-buttons .fr-btn {
  display: inline-block;
  float: none;
}
.fr-popup .fr-buttons .fr-btn i {
  float: left;
}
.fr-popup .fr-buttons .fr-separator {
  display: inline-block;
  float: none;
}
.fr-popup .fr-layer {
  width: 225px;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  margin: 10px;
  display: none;
}
@media (min-width: 768px) {
  .fr-popup .fr-layer {
    width: 300px;
  }
}
.fr-popup .fr-layer.fr-active {
  display: inline-block;
}
.fr-popup .fr-action-buttons {
  z-index: 7;
  height: 36px;
  text-align: right;
}
.fr-popup .fr-action-buttons button.fr-command {
  height: 36px;
  line-height: 1;
  color: #1e88e5;
  padding: 10px;
  cursor: pointer;
  text-decoration: none;
  border: none;
  background: none;
  font-size: 16px;
  outline: none;
  -webkit-transition: background 0.2s ease 0s;
  -moz-transition: background 0.2s ease 0s;
  -ms-transition: background 0.2s ease 0s;
  -o-transition: background 0.2s ease 0s;
  border-radius: 2px;
  -moz-border-radius: 2px;
  -webkit-border-radius: 2px;
  -moz-background-clip: padding;
  -webkit-background-clip: padding-box;
  background-clip: padding-box;
}
.fr-popup .fr-action-buttons button.fr-command + button {
  margin-left: 24px;
}
.fr-popup .fr-action-buttons button.fr-command:hover,
.fr-popup .fr-action-buttons button.fr-command:focus {
  background: #ebebeb;
  color: #1e88e5;
}
.fr-popup .fr-action-buttons button.fr-command:active {
  background: #d6d6d6;
  color: #1e88e5;
}
.fr-popup .fr-action-buttons button::-moz-focus-inner {
  border: 0;
}
.fr-popup .fr-checkbox {
  position: relative;
  display: inline-block;
  width: 16px;
  height: 16px;
  line-height: 1;
  -webkit-box-sizing: content-box;
  -moz-box-sizing: content-box;
  box-sizing: content-box;
  vertical-align: middle;
}
.fr-popup .fr-checkbox svg {
  margin-left: 2px;
  margin-top: 2px;
  display: none;
  width: 10px;
  height: 10px;
}
.fr-popup .fr-checkbox span {
  border: solid 1px #222222;
  border-radius: 2px;
  -moz-border-radius: 2px;
  -webkit-border-radius: 2px;
  -moz-background-clip: padding;
  -webkit-background-clip: padding-box;
  background-clip: padding-box;
  width: 16px;
  height: 16px;
  display: inline-block;
  position: relative;
  z-index: 1;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-transition: background 0.2s ease 0s, border-color 0.2s ease 0s;
  -moz-transition: background 0.2s ease 0s, border-color 0.2s ease 0s;
  -ms-transition: background 0.2s ease 0s, border-color 0.2s ease 0s;
  -o-transition: background 0.2s ease 0s, border-color 0.2s ease 0s;
}
.fr-popup .fr-checkbox input {
  position: absolute;
  z-index: 2;
  -webkit-opacity: 0;
  -moz-opacity: 0;
  opacity: 0;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
  border: 0 none;
  cursor: pointer;
  height: 16px;
  margin: 0;
  padding: 0;
  width: 16px;
  top: 1px;
  left: 1px;
}
.fr-popup .fr-checkbox input:checked + span {
  background: #1e88e5;
  border-color: #1e88e5;
}
.fr-popup .fr-checkbox input:checked + span svg {
  display: block;
}
.fr-popup .fr-checkbox input:focus + span {
  border-color: #1e88e5;
}
.fr-popup .fr-checkbox-line {
  font-size: 14px;
  line-height: 1.4px;
  margin-top: 10px;
}
.fr-popup .fr-checkbox-line label {
  cursor: pointer;
  margin: 0 5px;
  vertical-align: middle;
}
.fr-popup.fr-rtl {
  direction: rtl;
  text-align: right;
}
.fr-popup.fr-rtl .fr-action-buttons {
  text-align: left;
}
.fr-popup.fr-rtl .fr-input-line input + label,
.fr-popup.fr-rtl .fr-input-line textarea + label {
  left: auto;
  right: 0;
}
.fr-popup.fr-rtl .fr-buttons .fr-separator.fr-vs {
  float: right;
}
.fr-popup .fr-arrow {
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-bottom: 5px solid #222222;
  position: absolute;
  top: -9px;
  left: 50%;
  margin-left: -5px;
  display: inline-block;
}
.fr-popup.fr-above .fr-arrow {
  top: auto;
  bottom: -9px;
  border-bottom: 0;
  border-top: 5px solid #222222;
}
.fr-text-edit-layer {
  width: 250px;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  display: block !important;
}
.fr-toolbar {
  color: #222222;
  background: #ffffff;
  position: relative;
  z-index: 4;
  font-family: Arial, Helvetica, sans-serif;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  user-select: none;
  -o-user-select: none;
  -moz-user-select: none;
  -khtml-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  padding: 0 2px;
  border-radius: 2px;
  -moz-border-radius: 2px;
  -webkit-border-radius: 2px;
  -moz-background-clip: padding;
  -webkit-background-clip: padding-box;
  background-clip: padding-box;
  -webkit-box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 1px 1px rgba(0, 0, 0, 0.16);
  -moz-box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 1px 1px rgba(0, 0, 0, 0.16);
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 1px 1px rgba(0, 0, 0, 0.16);
  text-align: left;
  border: 0px;
  border-top: 5px solid #222222;
  text-rendering: optimizelegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  line-height: 1.2;
}
.fr-toolbar::after {
  clear: both;
  display: block;
  content: "";
  height: 0;
}
.fr-toolbar.fr-rtl {
  text-align: right;
}
.fr-toolbar.fr-inline {
  display: none;
  white-space: nowrap;
  position: absolute;
  margin-top: 10px;
}
.fr-toolbar.fr-inline .fr-arrow {
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-bottom: 5px solid #222222;
  position: absolute;
  top: -9px;
  left: 50%;
  margin-left: -5px;
  display: inline-block;
}
.fr-toolbar.fr-inline.fr-above {
  margin-top: -10px;
  -webkit-box-shadow: 0 -1px 3px rgba(0, 0, 0, 0.12), 0 -1px 1px 1px rgba(0, 0, 0, 0.16);
  -moz-box-shadow: 0 -1px 3px rgba(0, 0, 0, 0.12), 0 -1px 1px 1px rgba(0, 0, 0, 0.16);
  box-shadow: 0 -1px 3px rgba(0, 0, 0, 0.12), 0 -1px 1px 1px rgba(0, 0, 0, 0.16);
  border-bottom: 5px solid #222222;
  border-top: 0;
}
.fr-toolbar.fr-inline.fr-above .fr-arrow {
  top: auto;
  bottom: -9px;
  border-bottom: 0;
  border-top-color: inherit;
  border-top-style: solid;
  border-top-width: 5px;
}
.fr-toolbar.fr-top {
  top: 0;
  border-radius: 2px 2px 0 0;
  -moz-border-radius: 2px 2px 0 0;
  -webkit-border-radius: 2px 2px 0 0;
  -moz-background-clip: padding;
  -webkit-background-clip: padding-box;
  background-clip: padding-box;
  -webkit-box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 1px 1px rgba(0, 0, 0, 0.16);
  -moz-box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 1px 1px rgba(0, 0, 0, 0.16);
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 1px 1px rgba(0, 0, 0, 0.16);
}
.fr-toolbar.fr-bottom {
  bottom: 0;
  border-radius: 0 0 2px 2px;
  -moz-border-radius: 0 0 2px 2px;
  -webkit-border-radius: 0 0 2px 2px;
  -moz-background-clip: padding;
  -webkit-background-clip: padding-box;
  background-clip: padding-box;
  -webkit-box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 1px 1px rgba(0, 0, 0, 0.16);
  -moz-box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 1px 1px rgba(0, 0, 0, 0.16);
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 1px 1px rgba(0, 0, 0, 0.16);
}
.fr-separator {
  background: #ebebeb;
  display: block;
  vertical-align: top;
  float: left;
}
.fr-separator + .fr-separator {
  display: none;
}
.fr-separator.fr-vs {
  height: 34px;
  width: 1px;
  margin: 2px;
}
.fr-separator.fr-hs {
  clear: both;
  height: 1px;
  width: calc(100% - (2 * 2px));
  margin: 0 2px;
}
.fr-separator.fr-hidden {
  display: none !important;
}
.fr-rtl .fr-separator {
  float: right;
}
.fr-toolbar.fr-inline .fr-separator.fr-hs {
  float: none;
}
.fr-toolbar.fr-inline .fr-separator.fr-vs {
  float: none;
  display: inline-block;
}
.fr-visibility-helper {
  display: none;
  margin-left: 0px !important;
}
@media (min-width: 768px) {
  .fr-visibility-helper {
    margin-left: 1px !important;
  }
}
@media (min-width: 992px) {
  .fr-visibility-helper {
    margin-left: 2px !important;
  }
}
@media (min-width: 1200px) {
  .fr-visibility-helper {
    margin-left: 3px !important;
  }
}
.fr-opacity-0 {
  -webkit-opacity: 0;
  -moz-opacity: 0;
  opacity: 0;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
}
.fr-box {
  position: relative;
}
/**
 * Postion sticky hacks.
 */
.fr-sticky {
  position: -webkit-sticky;
  position: -moz-sticky;
  position: -ms-sticky;
  position: -o-sticky;
  position: sticky;
}
.fr-sticky-off {
  position: relative;
}
.fr-sticky-on {
  position: fixed;
}
.fr-sticky-on.fr-sticky-ios {
  position: absolute;
  left: 0;
  right: 0;
  width: auto !important;
}
.fr-sticky-dummy {
  display: none;
}
.fr-sticky-on + .fr-sticky-dummy,
.fr-sticky-box > .fr-sticky-dummy {
  display: block;
}
span.fr-sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
}

.clearfix::after {
  clear: both;
  display: block;
  content: "";
  height: 0;
}
.hide-by-clipping {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
}
.fr-box .fr-counter {
  position: absolute;
  bottom: 0px;
  padding: 5px;
  right: 0px;
  color: #cccccc;
  content: attr(data-chars);
  font-size: 15px;
  font-family: "Times New Roman", Georgia, Serif;
  z-index: 1;
  background: #ffffff;
  border-top: solid 1px #ebebeb;
  border-left: solid 1px #ebebeb;
  border-radius: 2px 0 0 0;
  -moz-border-radius: 2px 0 0 0;
  -webkit-border-radius: 2px 0 0 0;
  -moz-background-clip: padding;
  -webkit-background-clip: padding-box;
  background-clip: padding-box;
}
.fr-box.fr-rtl .fr-counter {
  left: 0px;
  right: auto;
  border-left: none;
  border-right: solid 1px #ebebeb;
  border-radius: 0 2px 0 0;
  -moz-border-radius: 0 2px 0 0;
  -webkit-border-radius: 0 2px 0 0;
  -moz-background-clip: padding;
  -webkit-background-clip: padding-box;
  background-clip: padding-box;
}
.fr-box.fr-code-view .fr-counter {
  display: none;
}

.clearfix::after {
  clear: both;
  display: block;
  content: "";
  height: 0;
}
.hide-by-clipping {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
}
textarea.fr-code {
  display: none;
  width: 100%;
  resize: none;
  -moz-resize: none;
  -webkit-resize: none;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  border: none;
  padding: 10px;
  margin: 0px;
  font-family: "Courier New", monospace;
  font-size: 14px;
  background: #ffffff;
  color: #000000;
  outline: none;
}
.fr-box.fr-rtl textarea.fr-code {
  direction: rtl;
}
.fr-box .CodeMirror {
  display: none;
}
.fr-box.fr-code-view textarea.fr-code {
  display: block;
}
.fr-box.fr-code-view.fr-inline {
  -webkit-box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 1px 1px rgba(0, 0, 0, 0.16);
  -moz-box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 1px 1px rgba(0, 0, 0, 0.16);
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 1px 1px rgba(0, 0, 0, 0.16);
}
.fr-box.fr-code-view .fr-element,
.fr-box.fr-code-view .fr-placeholder,
.fr-box.fr-code-view .fr-iframe {
  display: none;
}
.fr-box.fr-code-view .CodeMirror {
  display: block;
}
.fr-box.fr-inline.fr-code-view .fr-command.fr-btn.html-switch {
  display: block;
}
.fr-box.fr-inline .fr-command.fr-btn.html-switch {
  position: absolute;
  top: 0;
  right: 0;
  -webkit-box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 1px 1px rgba(0, 0, 0, 0.16);
  -moz-box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 1px 1px rgba(0, 0, 0, 0.16);
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 1px 1px rgba(0, 0, 0, 0.16);
  display: none;
  background: #ffffff;
  color: #222222;
  -moz-outline: 0;
  outline: 0;
  border: 0;
  line-height: 1;
  cursor: pointer;
  text-align: left;
  padding: 12px 12px;
  -webkit-transition: background 0.2s ease 0s;
  -moz-transition: background 0.2s ease 0s;
  -ms-transition: background 0.2s ease 0s;
  -o-transition: background 0.2s ease 0s;
  border-radius: 0;
  -moz-border-radius: 0;
  -webkit-border-radius: 0;
  -moz-background-clip: padding;
  -webkit-background-clip: padding-box;
  background-clip: padding-box;
  z-index: 2;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  text-decoration: none;
  user-select: none;
  -o-user-select: none;
  -moz-user-select: none;
  -khtml-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
}
.fr-box.fr-inline .fr-command.fr-btn.html-switch i {
  font-size: 14px;
  width: 14px;
  text-align: center;
}
.fr-box.fr-inline .fr-command.fr-btn.html-switch.fr-desktop:hover {
  background: #ebebeb;
}

.clearfix::after {
  clear: both;
  display: block;
  content: "";
  height: 0;
}
.hide-by-clipping {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
}
.fr-popup .fr-colors-tabs {
  -webkit-box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 1px 1px rgba(0, 0, 0, 0.16);
  -moz-box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 1px 1px rgba(0, 0, 0, 0.16);
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 1px 1px rgba(0, 0, 0, 0.16);
  margin-bottom: 5px;
  line-height: 16px;
  margin-left: -2px;
  margin-right: -2px;
}
.fr-popup .fr-colors-tabs .fr-colors-tab {
  display: inline-block;
  width: 50%;
  cursor: pointer;
  text-align: center;
  color: #222222;
  font-size: 13px;
  padding: 8px 0;
  position: relative;
}
.fr-popup .fr-colors-tabs .fr-colors-tab:hover,
.fr-popup .fr-colors-tabs .fr-colors-tab:focus {
  color: #1e88e5;
}
.fr-popup .fr-colors-tabs .fr-colors-tab[data-param1="background"]::after {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 2px;
  background: #1e88e5;
  content: '';
  -webkit-transition: transform 0.2s ease 0s;
  -moz-transition: transform 0.2s ease 0s;
  -ms-transition: transform 0.2s ease 0s;
  -o-transition: transform 0.2s ease 0s;
}
.fr-popup .fr-colors-tabs .fr-colors-tab.fr-selected-tab {
  color: #1e88e5;
}
.fr-popup .fr-colors-tabs .fr-colors-tab.fr-selected-tab[data-param1="text"] ~ [data-param1="background"]::after {
  -webkit-transform: translate3d(-100%, 0, 0);
  -moz-transform: translate3d(-100%, 0, 0);
  -ms-transform: translate3d(-100%, 0, 0);
  -o-transform: translate3d(-100%, 0, 0);
}
.fr-popup .fr-color-hex-layer {
  width: 100%;
  margin: 0px;
  padding: 10px;
}
.fr-popup .fr-color-hex-layer .fr-input-line {
  float: left;
  width: calc(100% - 50px);
  padding: 8px 0 0;
}
.fr-popup .fr-color-hex-layer .fr-action-buttons {
  float: right;
  width: 50px;
}
.fr-popup .fr-color-hex-layer .fr-action-buttons button.fr-command {
  background-color: #1e88e5;
  color: #FFF !important;
  border-radius: 2px;
  -moz-border-radius: 2px;
  -webkit-border-radius: 2px;
  -moz-background-clip: padding;
  -webkit-background-clip: padding-box;
  background-clip: padding-box;
  font-size: 13px;
  height: 32px;
}
.fr-popup .fr-color-hex-layer .fr-action-buttons button.fr-command:hover {
  background-color: #166dba;
  color: #FFF;
}
.fr-popup .fr-separator + .fr-colors-tabs {
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
  margin-left: 2px;
  margin-right: 2px;
}
.fr-popup .fr-color-set {
  line-height: 0;
  display: none;
}
.fr-popup .fr-color-set.fr-selected-set {
  display: block;
}
.fr-popup .fr-color-set > span {
  display: inline-block;
  width: 32px;
  height: 32px;
  position: relative;
  z-index: 1;
}
.fr-popup .fr-color-set > span > i,
.fr-popup .fr-color-set > span > svg {
  text-align: center;
  line-height: 32px;
  height: 32px;
  width: 32px;
  font-size: 13px;
  position: absolute;
  bottom: 0;
  cursor: default;
  left: 0;
}
.fr-popup .fr-color-set > span .fr-selected-color {
  color: #ffffff;
  font-family: FontAwesome;
  font-size: 13px;
  font-weight: 400;
  line-height: 32px;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  text-align: center;
  cursor: default;
}
.fr-popup .fr-color-set > span:hover,
.fr-popup .fr-color-set > span:focus {
  outline: 1px solid #222222;
  z-index: 2;
}
.fr-rtl .fr-popup .fr-colors-tabs .fr-colors-tab.fr-selected-tab[data-param1="text"] ~ [data-param1="background"]::after {
  -webkit-transform: translate3d(100%, 0, 0);
  -moz-transform: translate3d(100%, 0, 0);
  -ms-transform: translate3d(100%, 0, 0);
  -o-transform: translate3d(100%, 0, 0);
}

.clearfix::after {
  clear: both;
  display: block;
  content: "";
  height: 0;
}
.hide-by-clipping {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
}
.fr-drag-helper {
  background: #1e88e5;
  height: 2px;
  margin-top: -1px;
  -webkit-opacity: 0.2;
  -moz-opacity: 0.2;
  opacity: 0.2;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
  position: absolute;
  z-index: 2147483640;
  display: none;
}
.fr-drag-helper.fr-visible {
  display: block;
}
.fr-dragging {
  -webkit-opacity: 0.4;
  -moz-opacity: 0.4;
  opacity: 0.4;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
}

.clearfix::after {
  clear: both;
  display: block;
  content: "";
  height: 0;
}
.hide-by-clipping {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
}
.fr-popup .fr-emoticon {
  display: inline-block;
  font-size: 20px;
  width: 20px;
  padding: 5px;
  line-height: 1;
  cursor: default;
  font-weight: normal;
  font-family: "Apple Color Emoji", "Segoe UI Emoji", "NotoColorEmoji", "Segoe UI Symbol", "Android Emoji", "EmojiSymbols";
  -webkit-box-sizing: content-box;
  -moz-box-sizing: content-box;
  box-sizing: content-box;
}
.fr-popup .fr-emoticon img {
  height: 20px;
}
.fr-popup .fr-link:focus {
  outline: 0;
  background: #ebebeb;
}

.clearfix::after {
  clear: both;
  display: block;
  content: "";
  height: 0;
}
.hide-by-clipping {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
}
.fr-popup .fr-file-upload-layer {
  border: dashed 2px #bdbdbd;
  padding: 25px 0;
  position: relative;
  font-size: 14px;
  letter-spacing: 1px;
  line-height: 140%;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  text-align: center;
}
.fr-popup .fr-file-upload-layer:hover {
  background: #ebebeb;
}
.fr-popup .fr-file-upload-layer.fr-drop {
  background: #ebebeb;
  border-color: #1e88e5;
}
.fr-popup .fr-file-upload-layer .fr-form {
  -webkit-opacity: 0;
  -moz-opacity: 0;
  opacity: 0;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 2147483640;
  overflow: hidden;
  margin: 0 !important;
  padding: 0 !important;
  width: 100% !important;
}
.fr-popup .fr-file-upload-layer .fr-form input {
  cursor: pointer;
  position: absolute;
  right: 0px;
  top: 0px;
  bottom: 0px;
  width: 500%;
  height: 100%;
  margin: 0px;
  font-size: 400px;
}
.fr-popup .fr-file-progress-bar-layer {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
.fr-popup .fr-file-progress-bar-layer > h3 {
  font-size: 16px;
  margin: 10px 0;
  font-weight: normal;
}
.fr-popup .fr-file-progress-bar-layer > div.fr-action-buttons {
  display: none;
}
.fr-popup .fr-file-progress-bar-layer > div.fr-loader {
  background: #bcdbf7;
  height: 10px;
  width: 100%;
  margin-top: 20px;
  overflow: hidden;
  position: relative;
}
.fr-popup .fr-file-progress-bar-layer > div.fr-loader span {
  display: block;
  height: 100%;
  width: 0%;
  background: #1e88e5;
  -webkit-transition: width 0.2s ease 0s;
  -moz-transition: width 0.2s ease 0s;
  -ms-transition: width 0.2s ease 0s;
  -o-transition: width 0.2s ease 0s;
}
.fr-popup .fr-file-progress-bar-layer > div.fr-loader.fr-indeterminate span {
  width: 30% !important;
  position: absolute;
  top: 0;
  -webkit-animation: loading 2s linear infinite;
  -moz-animation: loading 2s linear infinite;
  -o-animation: loading 2s linear infinite;
  animation: loading 2s linear infinite;
}
.fr-popup .fr-file-progress-bar-layer.fr-error > div.fr-loader {
  display: none;
}
.fr-popup .fr-file-progress-bar-layer.fr-error > div.fr-action-buttons {
  display: block;
}
@keyframes loading {
  from {
    left: -25%;
  }
  to {
    left: 100%;
  }
}
@-webkit-keyframes loading {
  from {
    left: -25%;
  }
  to {
    left: 100%;
  }
}
@-moz-keyframes loading {
  from {
    left: -25%;
  }
  to {
    left: 100%;
  }
}
@-o-keyframes loading {
  from {
    left: -25%;
  }
  to {
    left: 100%;
  }
}

body.fr-fullscreen {
  overflow: hidden;
  height: 100%;
  width: 100%;
  position: fixed;
}
.fr-box.fr-fullscreen {
  margin: 0 !important;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 2147483630 !important;
  width: auto !important;
}
.fr-box.fr-fullscreen .fr-toolbar.fr-top {
  top: 0 !important;
}
.fr-box.fr-fullscreen .fr-toolbar.fr-bottom {
  bottom: 0 !important;
}

.clearfix::after {
  clear: both;
  display: block;
  content: "";
  height: 0;
}
.hide-by-clipping {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
}
.fr-modal .fr-modal-wrapper .fr-modal-body .fr-help-modal {
  text-align: left;
  padding: 20px 20px 10px;
}
.fr-modal .fr-modal-wrapper .fr-modal-body .fr-help-modal table {
  border-collapse: collapse;
  font-size: 14px;
  line-height: 1.5;
  width: 100%;
}
.fr-modal .fr-modal-wrapper .fr-modal-body .fr-help-modal table + table {
  margin-top: 20px;
}
.fr-modal .fr-modal-wrapper .fr-modal-body .fr-help-modal table tr {
  border: 0;
}
.fr-modal .fr-modal-wrapper .fr-modal-body .fr-help-modal table th,
.fr-modal .fr-modal-wrapper .fr-modal-body .fr-help-modal table td {
  padding: 6px 0 4px;
}
.fr-modal .fr-modal-wrapper .fr-modal-body .fr-help-modal table tbody tr {
  border-bottom: solid 1px #ebebeb;
}
.fr-modal .fr-modal-wrapper .fr-modal-body .fr-help-modal table tbody td:first-child {
  width: 60%;
  color: #646464;
}
.fr-modal .fr-modal-wrapper .fr-modal-body .fr-help-modal table tbody td:nth-child(n+2) {
  letter-spacing: 0.5px;
}

.clearfix::after {
  clear: both;
  display: block;
  content: "";
  height: 0;
}
.hide-by-clipping {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
}
.fr-element img {
  cursor: pointer;
}
.fr-image-resizer {
  position: absolute;
  border: solid 1px #1e88e5;
  display: none;
  user-select: none;
  -o-user-select: none;
  -moz-user-select: none;
  -khtml-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  -webkit-box-sizing: content-box;
  -moz-box-sizing: content-box;
  box-sizing: content-box;
  z-index: 2;
}
.fr-image-resizer.fr-active {
  display: block;
}
.fr-image-resizer .fr-handler {
  display: block;
  position: absolute;
  background: #1e88e5;
  border: solid 1px #ffffff;
  z-index: 4;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
.fr-image-resizer .fr-handler.fr-hnw {
  cursor: nw-resize;
}
.fr-image-resizer .fr-handler.fr-hne {
  cursor: ne-resize;
}
.fr-image-resizer .fr-handler.fr-hsw {
  cursor: sw-resize;
}
.fr-image-resizer .fr-handler.fr-hse {
  cursor: se-resize;
}
.fr-image-resizer .fr-handler {
  width: 12px;
  height: 12px;
}
.fr-image-resizer .fr-handler.fr-hnw {
  left: -6px;
  top: -6px;
}
.fr-image-resizer .fr-handler.fr-hne {
  right: -6px;
  top: -6px;
}
.fr-image-resizer .fr-handler.fr-hsw {
  left: -6px;
  bottom: -6px;
}
.fr-image-resizer .fr-handler.fr-hse {
  right: -6px;
  bottom: -6px;
}
@media (min-width: 1200px) {
  .fr-image-resizer .fr-handler {
    width: 10px;
    height: 10px;
  }
  .fr-image-resizer .fr-handler.fr-hnw {
    left: -5px;
    top: -5px;
  }
  .fr-image-resizer .fr-handler.fr-hne {
    right: -5px;
    top: -5px;
  }
  .fr-image-resizer .fr-handler.fr-hsw {
    left: -5px;
    bottom: -5px;
  }
  .fr-image-resizer .fr-handler.fr-hse {
    right: -5px;
    bottom: -5px;
  }
}
.fr-image-overlay {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 2147483640;
  display: none;
}
.fr-popup .fr-image-upload-layer {
  border: dashed 2px #bdbdbd;
  padding: 25px 0;
  position: relative;
  font-size: 14px;
  letter-spacing: 1px;
  line-height: 140%;
  text-align: center;
}
.fr-popup .fr-image-upload-layer:hover {
  background: #ebebeb;
}
.fr-popup .fr-image-upload-layer.fr-drop {
  background: #ebebeb;
  border-color: #1e88e5;
}
.fr-popup .fr-image-upload-layer .fr-form {
  -webkit-opacity: 0;
  -moz-opacity: 0;
  opacity: 0;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 2147483640;
  overflow: hidden;
  margin: 0 !important;
  padding: 0 !important;
  width: 100% !important;
}
.fr-popup .fr-image-upload-layer .fr-form input {
  cursor: pointer;
  position: absolute;
  right: 0px;
  top: 0px;
  bottom: 0px;
  width: 500%;
  height: 100%;
  margin: 0px;
  font-size: 400px;
}
.fr-popup .fr-image-progress-bar-layer > h3 {
  font-size: 16px;
  margin: 10px 0;
  font-weight: normal;
}
.fr-popup .fr-image-progress-bar-layer > div.fr-action-buttons {
  display: none;
}
.fr-popup .fr-image-progress-bar-layer > div.fr-loader {
  background: #bcdbf7;
  height: 10px;
  width: 100%;
  margin-top: 20px;
  overflow: hidden;
  position: relative;
}
.fr-popup .fr-image-progress-bar-layer > div.fr-loader span {
  display: block;
  height: 100%;
  width: 0%;
  background: #1e88e5;
  -webkit-transition: width 0.2s ease 0s;
  -moz-transition: width 0.2s ease 0s;
  -ms-transition: width 0.2s ease 0s;
  -o-transition: width 0.2s ease 0s;
}
.fr-popup .fr-image-progress-bar-layer > div.fr-loader.fr-indeterminate span {
  width: 30% !important;
  position: absolute;
  top: 0;
  -webkit-animation: loading 2s linear infinite;
  -moz-animation: loading 2s linear infinite;
  -o-animation: loading 2s linear infinite;
  animation: loading 2s linear infinite;
}
.fr-popup .fr-image-progress-bar-layer.fr-error > div.fr-loader {
  display: none;
}
.fr-popup .fr-image-progress-bar-layer.fr-error > div.fr-action-buttons {
  display: block;
}
.fr-image-size-layer .fr-image-group .fr-input-line {
  width: calc(50% - 5px);
  display: inline-block;
}
.fr-image-size-layer .fr-image-group .fr-input-line + .fr-input-line {
  margin-left: 10px;
}
.fr-uploading {
  -webkit-opacity: 0.4;
  -moz-opacity: 0.4;
  opacity: 0.4;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
}
@keyframes loading {
  from {
    left: -25%;
  }
  to {
    left: 100%;
  }
}
@-webkit-keyframes loading {
  from {
    left: -25%;
  }
  to {
    left: 100%;
  }
}
@-moz-keyframes loading {
  from {
    left: -25%;
  }
  to {
    left: 100%;
  }
}
@-o-keyframes loading {
  from {
    left: -25%;
  }
  to {
    left: 100%;
  }
}

.clearfix::after {
  clear: both;
  display: block;
  content: "";
  height: 0;
}
.hide-by-clipping {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
}
.fr-modal-head .fr-modal-head-line::after {
  clear: both;
  display: block;
  content: "";
  height: 0;
}
.fr-modal-head .fr-modal-head-line i.fr-modal-more,
.fr-modal-head .fr-modal-head-line svg.fr-modal-more {
  float: left;
  opacity: 1;
  -webkit-transition: padding 0.2s ease 0s, width 0.2s ease 0s, opacity 0.2s ease 0s;
  -moz-transition: padding 0.2s ease 0s, width 0.2s ease 0s, opacity 0.2s ease 0s;
  -ms-transition: padding 0.2s ease 0s, width 0.2s ease 0s, opacity 0.2s ease 0s;
  -o-transition: padding 0.2s ease 0s, width 0.2s ease 0s, opacity 0.2s ease 0s;
  padding: 12px;
}
.fr-modal-head .fr-modal-head-line i.fr-modal-more.fr-not-available,
.fr-modal-head .fr-modal-head-line svg.fr-modal-more.fr-not-available {
  opacity: 0;
  width: 0;
  padding: 12px 0;
}
.fr-modal-head .fr-modal-tags {
  display: none;
  text-align: left;
}
.fr-modal-head .fr-modal-tags a {
  display: inline-block;
  opacity: 0;
  padding: 6px 8px;
  margin: 8px 0 8px 8px;
  text-decoration: none;
  border-radius: 2px;
  -moz-border-radius: 2px;
  -webkit-border-radius: 2px;
  -moz-background-clip: padding;
  -webkit-background-clip: padding-box;
  background-clip: padding-box;
  color: #1e88e5;
  -webkit-transition: opacity 0.2s ease 0s, background 0.2s ease 0s;
  -moz-transition: opacity 0.2s ease 0s, background 0.2s ease 0s;
  -ms-transition: opacity 0.2s ease 0s, background 0.2s ease 0s;
  -o-transition: opacity 0.2s ease 0s, background 0.2s ease 0s;
  cursor: pointer;
}
.fr-modal-head .fr-modal-tags a:focus {
  outline: none;
}
.fr-modal-head .fr-modal-tags a.fr-selected-tag {
  background: #d6d6d6;
}
div.fr-modal-body .fr-preloader {
  display: block;
  margin: 50px auto;
}
div.fr-modal-body div.fr-image-list {
  text-align: center;
  margin: 0 10px;
  padding: 0;
}
div.fr-modal-body div.fr-image-list::after {
  clear: both;
  display: block;
  content: "";
  height: 0;
}
div.fr-modal-body div.fr-image-list .fr-list-column {
  float: left;
  width: calc((100% - 10px) / 2);
}
@media (min-width: 768px) and (max-width: 1199px) {
  div.fr-modal-body div.fr-image-list .fr-list-column {
    width: calc((100% - 20px) / 3);
  }
}
@media (min-width: 1200px) {
  div.fr-modal-body div.fr-image-list .fr-list-column {
    width: calc((100% - 30px) / 4);
  }
}
div.fr-modal-body div.fr-image-list .fr-list-column + .fr-list-column {
  margin-left: 10px;
}
div.fr-modal-body div.fr-image-list div.fr-image-container {
  position: relative;
  width: 100%;
  display: block;
  -webkit-box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 1px 1px rgba(0, 0, 0, 0.16);
  -moz-box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 1px 1px rgba(0, 0, 0, 0.16);
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 1px 1px rgba(0, 0, 0, 0.16);
  border-radius: 2px;
  -moz-border-radius: 2px;
  -webkit-border-radius: 2px;
  -moz-background-clip: padding;
  -webkit-background-clip: padding-box;
  background-clip: padding-box;
  overflow: hidden;
}
div.fr-modal-body div.fr-image-list div.fr-image-container:first-child {
  margin-top: 10px;
}
div.fr-modal-body div.fr-image-list div.fr-image-container + div {
  margin-top: 10px;
}
div.fr-modal-body div.fr-image-list div.fr-image-container.fr-image-deleting::after {
  position: absolute;
  -webkit-opacity: 0.5;
  -moz-opacity: 0.5;
  opacity: 0.5;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
  -webkit-transition: opacity 0.2s ease 0s;
  -moz-transition: opacity 0.2s ease 0s;
  -ms-transition: opacity 0.2s ease 0s;
  -o-transition: opacity 0.2s ease 0s;
  background: #000000;
  content: "";
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 2;
}
div.fr-modal-body div.fr-image-list div.fr-image-container.fr-image-deleting::before {
  content: attr(data-deleting);
  color: #ffffff;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  margin: auto;
  position: absolute;
  z-index: 3;
  font-size: 15px;
  height: 20px;
}
div.fr-modal-body div.fr-image-list div.fr-image-container.fr-empty {
  height: 95px;
  background: #cccccc;
  z-index: 1;
}
div.fr-modal-body div.fr-image-list div.fr-image-container.fr-empty::after {
  position: absolute;
  margin: auto;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  content: attr(data-loading);
  display: inline-block;
  height: 20px;
}
div.fr-modal-body div.fr-image-list div.fr-image-container img {
  width: 100%;
  vertical-align: middle;
  position: relative;
  z-index: 2;
  -webkit-opacity: 1;
  -moz-opacity: 1;
  opacity: 1;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
  -webkit-transition: opacity 0.2s ease 0s, filter 0.2s ease 0s;
  -moz-transition: opacity 0.2s ease 0s, filter 0.2s ease 0s;
  -ms-transition: opacity 0.2s ease 0s, filter 0.2s ease 0s;
  -o-transition: opacity 0.2s ease 0s, filter 0.2s ease 0s;
  -webkit-transform: translateZ(0);
  -moz-transform: translateZ(0);
  -ms-transform: translateZ(0);
  -o-transform: translateZ(0);
}
div.fr-modal-body div.fr-image-list div.fr-image-container.fr-mobile-selected img {
  -webkit-opacity: 0.75;
  -moz-opacity: 0.75;
  opacity: 0.75;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
}
div.fr-modal-body div.fr-image-list div.fr-image-container.fr-mobile-selected .fr-delete-img,
div.fr-modal-body div.fr-image-list div.fr-image-container.fr-mobile-selected .fr-insert-img {
  display: inline-block;
}
div.fr-modal-body div.fr-image-list div.fr-image-container .fr-delete-img,
div.fr-modal-body div.fr-image-list div.fr-image-container .fr-insert-img {
  display: none;
  top: 50%;
  border-radius: 100%;
  -moz-border-radius: 100%;
  -webkit-border-radius: 100%;
  -moz-background-clip: padding;
  -webkit-background-clip: padding-box;
  background-clip: padding-box;
  -webkit-transition: background 0.2s ease 0s, color 0.2s ease 0s;
  -moz-transition: background 0.2s ease 0s, color 0.2s ease 0s;
  -ms-transition: background 0.2s ease 0s, color 0.2s ease 0s;
  -o-transition: background 0.2s ease 0s, color 0.2s ease 0s;
  -webkit-box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 1px 1px rgba(0, 0, 0, 0.16);
  -moz-box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 1px 1px rgba(0, 0, 0, 0.16);
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 1px 1px rgba(0, 0, 0, 0.16);
  position: absolute;
  cursor: pointer;
  margin: 0;
  width: 36px;
  height: 36px;
  line-height: 36px;
  text-decoration: none;
  z-index: 3;
}
div.fr-modal-body div.fr-image-list div.fr-image-container .fr-delete-img {
  background: #b8312f;
  color: #ffffff;
  left: 50%;
  -webkit-transform: translateY(-50%) translateX(25%);
  -moz-transform: translateY(-50%) translateX(25%);
  -ms-transform: translateY(-50%) translateX(25%);
  -o-transform: translateY(-50%) translateX(25%);
}
div.fr-modal-body div.fr-image-list div.fr-image-container .fr-insert-img {
  background: #ffffff;
  color: #1e88e5;
  left: 50%;
  -webkit-transform: translateY(-50%) translateX(-125%);
  -moz-transform: translateY(-50%) translateX(-125%);
  -ms-transform: translateY(-50%) translateX(-125%);
  -o-transform: translateY(-50%) translateX(-125%);
}
.fr-desktop .fr-modal-wrapper .fr-modal-head .fr-modal-tags a:hover {
  background: #ebebeb;
}
.fr-desktop .fr-modal-wrapper .fr-modal-head .fr-modal-tags a.fr-selected-tag {
  background: #d6d6d6;
}
.fr-desktop .fr-modal-wrapper div.fr-modal-body div.fr-image-list div.fr-image-container:hover img {
  -webkit-opacity: 0.75;
  -moz-opacity: 0.75;
  opacity: 0.75;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
}
.fr-desktop .fr-modal-wrapper div.fr-modal-body div.fr-image-list div.fr-image-container:hover .fr-delete-img,
.fr-desktop .fr-modal-wrapper div.fr-modal-body div.fr-image-list div.fr-image-container:hover .fr-insert-img {
  display: inline-block;
}
.fr-desktop .fr-modal-wrapper div.fr-modal-body div.fr-image-list div.fr-image-container .fr-delete-img:hover {
  background: #bf4644;
  color: #ffffff;
}
.fr-desktop .fr-modal-wrapper div.fr-modal-body div.fr-image-list div.fr-image-container .fr-insert-img:hover {
  background: #ebebeb;
}

.clearfix::after {
  clear: both;
  display: block;
  content: "";
  height: 0;
}
.hide-by-clipping {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
}
.fr-line-breaker {
  cursor: text;
  border-top: 1px solid #1e88e5;
  position: fixed;
  z-index: 2;
  display: none;
}
.fr-line-breaker.fr-visible {
  display: block;
}
.fr-line-breaker a.fr-floating-btn {
  position: absolute;
  left: calc(50% - (32px / 2));
  top: -16px;
}

.clearfix::after {
  clear: both;
  display: block;
  content: "";
  height: 0;
}
.hide-by-clipping {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
}
.fr-quick-insert {
  position: absolute;
  z-index: 2147483639;
  white-space: nowrap;
  padding-right: 5px;
  margin-left: -5px;
  -webkit-box-sizing: content-box;
  -moz-box-sizing: content-box;
  box-sizing: content-box;
}
.fr-quick-insert.fr-on a.fr-floating-btn svg {
  -webkit-transform: rotate(135deg);
  -moz-transform: rotate(135deg);
  -ms-transform: rotate(135deg);
  -o-transform: rotate(135deg);
}
.fr-quick-insert.fr-hidden {
  display: none;
}
.fr-qi-helper {
  position: absolute;
  z-index: 3;
  padding-left: 16px;
  white-space: nowrap;
}
.fr-qi-helper a.fr-btn.fr-floating-btn {
  text-align: center;
  display: inline-block;
  color: #222222;
  -webkit-opacity: 0;
  -moz-opacity: 0;
  opacity: 0;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
  -webkit-transform: scale(0);
  -moz-transform: scale(0);
  -ms-transform: scale(0);
  -o-transform: scale(0);
}
.fr-qi-helper a.fr-btn.fr-floating-btn.fr-size-1 {
  -webkit-opacity: 1;
  -moz-opacity: 1;
  opacity: 1;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
  -webkit-transform: scale(1);
  -moz-transform: scale(1);
  -ms-transform: scale(1);
  -o-transform: scale(1);
}

.clearfix::after {
  clear: both;
  display: block;
  content: "";
  height: 0;
}
.hide-by-clipping {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
}
.fr-modal .fr-modal-wrapper .fr-modal-body .fr-special-characters-modal {
  text-align: left;
  padding: 20px 20px 10px;
}
.fr-modal .fr-modal-wrapper .fr-modal-body .fr-special-characters-modal .fr-special-characters-list {
  margin-bottom: 20px;
}
.fr-modal .fr-modal-wrapper .fr-modal-body .fr-special-characters-modal .fr-special-characters-title {
  font-weight: bold;
  font-size: 14px;
  padding: 6px 0 4px;
  margin: 0 0 5px;
}
.fr-modal .fr-modal-wrapper .fr-modal-body .fr-special-characters-modal .fr-special-character {
  display: inline-block;
  font-size: 16px;
  width: 20px;
  height: 20px;
  padding: 5px;
  line-height: 20px;
  cursor: default;
  font-weight: normal;
  -webkit-box-sizing: content-box;
  -moz-box-sizing: content-box;
  box-sizing: content-box;
  text-align: center;
  border: 1px solid #cccccc;
  margin: -1px 0 0 -1px;
}

.clearfix::after {
  clear: both;
  display: block;
  content: "";
  height: 0;
}
.hide-by-clipping {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
}
.fr-element table td.fr-selected-cell,
.fr-element table th.fr-selected-cell {
  border: 1px double #1e88e5;
}
.fr-element table tr {
  user-select: none;
  -o-user-select: none;
  -moz-user-select: none;
  -khtml-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
}
.fr-element table td,
.fr-element table th {
  user-select: text;
  -o-user-select: text;
  -moz-user-select: text;
  -khtml-user-select: text;
  -webkit-user-select: text;
  -ms-user-select: text;
}
.fr-element .fr-no-selection table td,
.fr-element .fr-no-selection table th {
  user-select: none;
  -o-user-select: none;
  -moz-user-select: none;
  -khtml-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
}
.fr-table-resizer {
  cursor: col-resize;
  position: absolute;
  z-index: 3;
  display: none;
}
.fr-table-resizer.fr-moving {
  z-index: 2;
}
.fr-table-resizer div {
  -webkit-opacity: 0;
  -moz-opacity: 0;
  opacity: 0;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
  border-right: 1px solid #1e88e5;
}
.fr-no-selection {
  user-select: none;
  -o-user-select: none;
  -moz-user-select: none;
  -khtml-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
}
.fr-popup .fr-table-colors-hex-layer {
  width: 100%;
  margin: 0px;
  padding: 10px;
}
.fr-popup .fr-table-colors-hex-layer .fr-input-line {
  float: left;
  width: calc(100% - 50px);
  padding: 8px 0 0;
}
.fr-popup .fr-table-colors-hex-layer .fr-action-buttons {
  float: right;
  width: 50px;
}
.fr-popup .fr-table-colors-hex-layer .fr-action-buttons button {
  background-color: #1e88e5;
  color: #FFF;
  border-radius: 2px;
  -moz-border-radius: 2px;
  -webkit-border-radius: 2px;
  -moz-background-clip: padding;
  -webkit-background-clip: padding-box;
  background-clip: padding-box;
  font-size: 13px;
  height: 32px;
}
.fr-popup .fr-table-colors-hex-layer .fr-action-buttons button:hover {
  background-color: #166dba;
  color: #FFF;
}
.fr-popup .fr-table-size .fr-table-size-info {
  text-align: center;
  font-size: 14px;
  padding: 8px;
}
.fr-popup .fr-table-size .fr-select-table-size {
  line-height: 0;
  padding: 0 5px 5px;
  white-space: nowrap;
}
.fr-popup .fr-table-size .fr-select-table-size > span {
  display: inline-block;
  padding: 0px 4px 4px 0;
  background: transparent;
}
.fr-popup .fr-table-size .fr-select-table-size > span > span {
  display: inline-block;
  width: 18px;
  height: 18px;
  border: 1px solid #dddddd;
}
.fr-popup .fr-table-size .fr-select-table-size > span.hover {
  background: transparent;
}
.fr-popup .fr-table-size .fr-select-table-size > span.hover > span {
  background: rgba(30, 136, 229, 0.3);
  border: solid 1px #1e88e5;
}
.fr-popup .fr-table-size .fr-select-table-size .new-line::after {
  clear: both;
  display: block;
  content: "";
  height: 0;
}
.fr-popup.fr-above .fr-table-size .fr-select-table-size > span {
  display: inline-block !important;
}
.fr-popup .fr-table-colors-buttons {
  margin-bottom: 5px;
}
.fr-popup .fr-table-colors {
  line-height: 0;
  display: block;
}
.fr-popup .fr-table-colors > span {
  display: inline-block;
  width: 32px;
  height: 32px;
  position: relative;
  z-index: 1;
}
.fr-popup .fr-table-colors > span > i {
  text-align: center;
  line-height: 32px;
  height: 32px;
  width: 32px;
  font-size: 13px;
  position: absolute;
  bottom: 0;
  cursor: default;
  left: 0;
}
.fr-popup .fr-table-colors > span:focus {
  outline: 1px solid #222222;
  z-index: 2;
}
.fr-popup.fr-desktop .fr-table-size .fr-select-table-size > span > span {
  width: 12px;
  height: 12px;
}
.fr-insert-helper {
  position: absolute;
  z-index: 9999;
  white-space: nowrap;
}

.clearfix::after {
  clear: both;
  display: block;
  content: "";
  height: 0;
}
.hide-by-clipping {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
}
.fr-element .fr-video {
  user-select: none;
  -o-user-select: none;
  -moz-user-select: none;
  -khtml-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
}
.fr-element .fr-video::after {
  position: absolute;
  content: '';
  z-index: 1;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  cursor: pointer;
  display: block;
  background: rgba(0, 0, 0, 0);
}
.fr-element .fr-video.fr-active > * {
  z-index: 2;
  position: relative;
}
.fr-element .fr-video > * {
  -webkit-box-sizing: content-box;
  -moz-box-sizing: content-box;
  box-sizing: content-box;
  max-width: 100%;
  border: none;
}
.fr-box .fr-video-resizer {
  position: absolute;
  border: solid 1px #1e88e5;
  display: none;
  user-select: none;
  -o-user-select: none;
  -moz-user-select: none;
  -khtml-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
}
.fr-box .fr-video-resizer.fr-active {
  display: block;
}
.fr-box .fr-video-resizer .fr-handler {
  display: block;
  position: absolute;
  background: #1e88e5;
  border: solid 1px #ffffff;
  z-index: 4;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
.fr-box .fr-video-resizer .fr-handler.fr-hnw {
  cursor: nw-resize;
}
.fr-box .fr-video-resizer .fr-handler.fr-hne {
  cursor: ne-resize;
}
.fr-box .fr-video-resizer .fr-handler.fr-hsw {
  cursor: sw-resize;
}
.fr-box .fr-video-resizer .fr-handler.fr-hse {
  cursor: se-resize;
}
.fr-box .fr-video-resizer .fr-handler {
  width: 12px;
  height: 12px;
}
.fr-box .fr-video-resizer .fr-handler.fr-hnw {
  left: -6px;
  top: -6px;
}
.fr-box .fr-video-resizer .fr-handler.fr-hne {
  right: -6px;
  top: -6px;
}
.fr-box .fr-video-resizer .fr-handler.fr-hsw {
  left: -6px;
  bottom: -6px;
}
.fr-box .fr-video-resizer .fr-handler.fr-hse {
  right: -6px;
  bottom: -6px;
}
@media (min-width: 1200px) {
  .fr-box .fr-video-resizer .fr-handler {
    width: 10px;
    height: 10px;
  }
  .fr-box .fr-video-resizer .fr-handler.fr-hnw {
    left: -5px;
    top: -5px;
  }
  .fr-box .fr-video-resizer .fr-handler.fr-hne {
    right: -5px;
    top: -5px;
  }
  .fr-box .fr-video-resizer .fr-handler.fr-hsw {
    left: -5px;
    bottom: -5px;
  }
  .fr-box .fr-video-resizer .fr-handler.fr-hse {
    right: -5px;
    bottom: -5px;
  }
}
.fr-popup .fr-video-size-layer .fr-video-group .fr-input-line {
  width: calc(50% - 5px);
  display: inline-block;
}
.fr-popup .fr-video-size-layer .fr-video-group .fr-input-line + .fr-input-line {
  margin-left: 10px;
}
.fr-popup .fr-video-upload-layer {
  border: dashed 2px #bdbdbd;
  padding: 25px 0;
  position: relative;
  font-size: 14px;
  letter-spacing: 1px;
  line-height: 140%;
  text-align: center;
}
.fr-popup .fr-video-upload-layer:hover {
  background: #ebebeb;
}
.fr-popup .fr-video-upload-layer.fr-drop {
  background: #ebebeb;
  border-color: #1e88e5;
}
.fr-popup .fr-video-upload-layer .fr-form {
  -webkit-opacity: 0;
  -moz-opacity: 0;
  opacity: 0;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 2147483640;
  overflow: hidden;
  margin: 0 !important;
  padding: 0 !important;
  width: 100% !important;
}
.fr-popup .fr-video-upload-layer .fr-form input {
  cursor: pointer;
  position: absolute;
  right: 0px;
  top: 0px;
  bottom: 0px;
  width: 500%;
  height: 100%;
  margin: 0px;
  font-size: 400px;
}
.fr-popup .fr-video-progress-bar-layer > h3 {
  font-size: 16px;
  margin: 10px 0;
  font-weight: normal;
}
.fr-popup .fr-video-progress-bar-layer > div.fr-action-buttons {
  display: none;
}
.fr-popup .fr-video-progress-bar-layer > div.fr-loader {
  background: #bcdbf7;
  height: 10px;
  width: 100%;
  margin-top: 20px;
  overflow: hidden;
  position: relative;
}
.fr-popup .fr-video-progress-bar-layer > div.fr-loader span {
  display: block;
  height: 100%;
  width: 0%;
  background: #1e88e5;
  -webkit-transition: width 0.2s ease 0s;
  -moz-transition: width 0.2s ease 0s;
  -ms-transition: width 0.2s ease 0s;
  -o-transition: width 0.2s ease 0s;
}
.fr-popup .fr-video-progress-bar-layer > div.fr-loader.fr-indeterminate span {
  width: 30% !important;
  position: absolute;
  top: 0;
  -webkit-animation: loading 2s linear infinite;
  -moz-animation: loading 2s linear infinite;
  -o-animation: loading 2s linear infinite;
  animation: loading 2s linear infinite;
}
.fr-popup .fr-video-progress-bar-layer.fr-error > div.fr-loader {
  display: none;
}
.fr-popup .fr-video-progress-bar-layer.fr-error > div.fr-action-buttons {
  display: block;
}
.fr-video-overlay {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 2147483640;
  display: none;
}
